<template>
<v-layout column>
  <v-app-bar app flat color="primary">
  <span style="cursor:pointer; font-weight:700; font-size:30px; font-family: 'Montserrat', sans-serif; letter-spacing: 4px; color:white">monitor</span>
<template v-slot:extension>
<v-tabs
  
        v-model="tab"
        background-color="primary"
        dark
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
</template>
  </v-app-bar>
  
  
      <v-tabs-items :key="update" v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <v-card flat>
            <v-card-text><div style="padding:10px;" v-html="item.html" /></v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
</v-layout>
</template>

<script>
import CONSTS from '@/consts'

export default {
  data: () => ({
    rawHtml: '',
    update: 0,
    tab: 0,
      items: [
        { tab: 'Privacy', content: 'Tab 1 Content' },
        { tab: 'Terms of Use', content: 'Tab 2 Content' },
      ],
  }),
  methods: {
    async getHTML() {
      const resp = await fetch(CONSTS.privacyUrl)
      this.items[0].html = await resp.text()
      this.update++
    },
    async getTerms() {
      const resp = await fetch(CONSTS.termsUrl)
      this.items[1].html = await resp.text()
      this.update++
    }
  },
  mounted() {
    this.getHTML()
    this.getTerms()
  }
}
</script>

<style></style>
